import { gql } from '@apollo/client';

const GET_PAGINATED_PROJECT_USERS = gql`
  query getUsersByProject(
    $after: String
    $first: Int
    $projectId: String!
    $filter: String
    $groupId: Int
    $status: EUserStatus
    $shouldReturnUnassignedOnly: Boolean
  ) {
    user {
      byProject(
        after: $after
        first: $first
        projectId: $projectId
        filter: $filter
        groupId: $groupId
        status: $status
        returnOnlyNonAssigned: $shouldReturnUnassignedOnly
      ) {
        totalCount
        edges {
          cursor
          node {
            id
            communityName
            status
            firstName: firstname
            lastName: lastname
            isProjectAdmin(projectId:$projectId)
            credential {
              email
              phoneNumber
            }
            created
            activeGroups(first: 5, projectId: $projectId) {
              edges {
                cursor
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export { GET_PAGINATED_PROJECT_USERS };
