import { ApolloQueryResult } from '@apollo/client';
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_PAGINATED_PROJECT_MEMBERS } from './useGetPaginatedProjectMembers.graphql';
import {
  IGetPaginatedProjectMembersQueryGraphQLResponse,
  IGetPaginatedProjectMembersQueryVariables,
  IHandleGetPaginatedProjectMembersParams,
  IUseGetPaginatedProjectMembers,
  IUseGetPaginatedProjectMembersOptions,
} from './useGetPaginatedProjectMembers.interfaces';

const useGetPaginatedProjectMembers = (options?: IUseGetPaginatedProjectMembersOptions): IUseGetPaginatedProjectMembers => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPaginatedProjectMembersOptions);

  const [executeGetPaginatedProjectMembers, { fetchMore, loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetPaginatedProjectMembersQueryGraphQLResponse,
    IGetPaginatedProjectMembersQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ membership: { getPaginatedProjectMembers: membershipConnection } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          membershipConnection,
        });
      },
      onError,
    },
    query: query ?? GET_PAGINATED_PROJECT_MEMBERS,
    token,
  });

  const handleFetchMorePaginatedProjectMembers = ({
    after,
    first,
    filter,
    projectId,
    status,
  }: IHandleGetPaginatedProjectMembersParams): Promise<ApolloQueryResult<IGetPaginatedProjectMembersQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (
        previousQueryResult,
        {
          fetchMoreResult: {
            membership: { getPaginatedProjectMembers: newlyFetchedMembershipConnection },
          },
        },
      ) => {
        const { edges: newlyFetchedEdges } = newlyFetchedMembershipConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          membership: { getPaginatedProjectMembers: previouslyFetchedMembershipConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedMembershipConnection;

        return {
          ...previousQueryResult,
          membership: {
            ...previouslyFetchedMembershipConnection,
            getPaginatedProjectMembers: {
              ...newlyFetchedMembershipConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        filter,
        projectId,
        status,
      },
    });
  };

  const handleGetPaginatedProjectMembers = async ({ after, filter, first, projectId, status }: IHandleGetPaginatedProjectMembersParams) => {
    await executeGetPaginatedProjectMembers({
      variables: {
        after,
        filter,
        first,
        projectId,
        status,
      },
    });
  };

  return {
    handleFetchMorePaginatedProjectMembers,
    handleGetPaginatedProjectMembers,
    isLoading,
  };
};

export { useGetPaginatedProjectMembers };
